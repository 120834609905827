/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { Dispatch, SetStateAction, useState, createContext, useContext } from 'react'
import { LinkGrid } from '../../../../components/linkgrid/LinkGrid'
import {
  ButtonWrapper,
  DropdownWrapper,
  LinkWrapper,
  NextButton,
  SecondaryLinksWrapper,
  StyledLink,
  StyledSelect,
} from './styledBannerCta'
import { CtaBannerFragment } from './__generated__/bannerCta'

type CTALinksProps = {
  data: CtaBannerFragment[]
}

const CTALinksContext = createContext<{
  selectedCTAFilter: number | undefined
  setSelectedCTAFilter: Dispatch<SetStateAction<number | undefined>>
} | undefined>(undefined)

export const CTALinksProvider: React.VFC<{ children: React.ReactNode }> = ({ children }) => {
  const [selectedCTAFilter, setSelectedCTAFilter] = useState<number | undefined>(undefined)

  return (
    <CTALinksContext.Provider value={{ selectedCTAFilter, setSelectedCTAFilter }}>
      {children}
    </CTALinksContext.Provider>
  )
}

export const useCTALinksContext = () => {
  const context = useContext(CTALinksContext)

  if (!context) {
    throw new Error('useCTALinksContext must be used within a CTALinksProvider')
  }

  return context
}

export const MobileLinks: React.VFC<CTALinksProps> = ({ data }) => {
  const [levelOne, setLevelOne] = useState(0)
  const [labelOne, setLabelOne] = useState<string|undefined>(data?.[0]?.ctaLinkTitle?.replace(/ /g,'-').toLocaleLowerCase())
  const [levelTwo, setLevelTwo] = useState('#')
  const [targetTwo, setTargetTwo] = useState<string|null|undefined>('')
  const [labelTwo, setLabelTwo] = useState<string|undefined>(undefined)
  const { selectedCTAFilter, setSelectedCTAFilter } = useCTALinksContext()

  const handleLevelOneSelect = (event) => {
    setLevelOne(event.target.value)
    setLabelOne(data[event.target.value]?.ctaLinkTitle?.replace(/ /g,'-').toLocaleLowerCase())
    setLabelTwo(data[event.target.value]?.ctaLinks?.[0]?.ctaLink?.customText?.replace(/ /g,'-').toLocaleLowerCase())
    event.preventDefault()

    return false
  }

  const handleLevelTwoSelect = (index) => {
    if (typeof selectedCTAFilter === 'number' && typeof index === 'number') {
      const levelTwoSelect = data?.[selectedCTAFilter]?.ctaLinks?.[index]?.ctaLink?.url
      const levelTwoLabel = data?.[selectedCTAFilter]?.ctaLinks?.[index]?.ctaLink?.customText?.replace(/ /g,'-').toLocaleLowerCase()
      const levelTwoTarget = data?.[selectedCTAFilter]?.ctaLinks?.[index]?.ctaLink?.target

      if (typeof selectedCTAFilter === 'number' && levelTwoSelect) {
        setLabelTwo(levelTwoLabel)
        setLevelTwo(levelTwoSelect)
        setTargetTwo(levelTwoTarget)
      }
    }
  }

  const handleNextButtonClick = () => {
    setSelectedCTAFilter(levelOne)

    if (typeof levelOne === 'number') {
      const levelTwoSelect = data?.[levelOne]?.ctaLinks?.[0]?.ctaLink?.url
      const levelTwoLabel = data?.[levelOne]?.ctaLinks?.[0]?.ctaLink?.customText?.replace(/ /g,'-').toLocaleLowerCase()
      const levelTwoTarget = data?.[levelOne]?.ctaLinks?.[0]?.ctaLink?.target

      if (levelTwoSelect) {
        setLevelTwo(levelTwoSelect)
        setLabelTwo(levelTwoLabel)
        setTargetTwo(levelTwoTarget)
      }
    }
  }

  const handleGoButtonClick = () => {
    if (targetTwo === '_blank' && typeof levelTwo === 'number') {
      window.open(levelTwo, '_blank')
    } else if (levelTwo) {
      window.location.href = levelTwo
    }
  }

  return (
    <DropdownWrapper>
      <SecondaryLinksWrapper>
        {typeof selectedCTAFilter !== 'number' ? (
          <>
            <StyledSelect
              //@ts-ignore
              options={data.map((item, index) => { return { text: item.ctaLinkTitle, value: index }})}
              onChange={handleLevelOneSelect}
              defaultValue={0}
            />
            <ButtonWrapper>
              <NextButton
                primary
                id="lvl-1"
                data-next={labelOne}
                buttonColor="black"
                onClick={handleNextButtonClick}
              >
                Next
              </NextButton>
            </ButtonWrapper>
          </>
        ) : (
          <>
            <StyledSelect
              //@ts-ignore
              options={data?.[selectedCTAFilter]?.ctaLinks?.map((item, index) => { return typeof item?.ctaLink?.url === 'string' && { text: item?.ctaLink?.customText, value: index }})}
              onChange={(e) => handleLevelTwoSelect(e.target.value)}
            />
            <ButtonWrapper>
              <NextButton
                primary
                id="lvl-2"
                data-go={labelTwo}
                buttonColor="orange"
                onClick={handleGoButtonClick}
              >
                GO
              </NextButton>
              <a onClick={() => setSelectedCTAFilter(undefined)}>Back</a>
            </ButtonWrapper>
          </>
        )}
      </SecondaryLinksWrapper>
    </DropdownWrapper>
  )
}


export const DesktopLinks: React.VFC<CTALinksProps> = ({ data }) => {
  const { selectedCTAFilter, setSelectedCTAFilter } = useCTALinksContext()

  return typeof selectedCTAFilter === 'undefined' ? (
    <LinkWrapper variant="dark">
      {data?.map((link, idx) => link?.ctaLinkTitle && (
        <StyledLink linkWrapper key={idx}>
          {/* @ts-ignore */}
          <LinkGrid infoClass="info-finder-lvl-1" link onClick={(event) => {
            event.preventDefault()
            setSelectedCTAFilter((prevFilter) => (prevFilter === idx ? undefined : idx))

            return false
          }}>
            {link?.ctaLinkTitle}
          </LinkGrid>
        </StyledLink>
      ))}
    </LinkWrapper>
  ) : (
    <SecondaryLinksWrapper>
      <LinkWrapper variant="dark">
        {data?.[selectedCTAFilter]?.ctaLinks?.map((link, idx) => link?.ctaLink?.url && (
          <StyledLink linkWrapper key={idx}>
            {/* @ts-ignore */}
            <LinkGrid link target={link.ctaLink.target} infoClass="info-finder-lvl-2" href={link.ctaLink.url}>{link?.ctaLink?.customText}</LinkGrid>
          </StyledLink>
        ))}
      </LinkWrapper>
      <a onClick={() => setSelectedCTAFilter(undefined)}>Back</a>
    </SecondaryLinksWrapper>
  )
}